<template>
    <div class="modal-add-product">
        <div class="head flex-row flex-between items-start">
            <div class="title">불합격 이유 선택</div>
        </div>
        <div class="reason-wrap m-t-16">
            <div
                :key="reason"
                v-for="reason in reasons"
                class="reason-list"
                :class="reason === selectedReason ? 'reason-list selected' : 'reason-list'"
                @click="selectReason(reason)"
            >
                {{ reason }}
            </div>
        </div>
        <div class="buttons m-t-16">
            <div class="flex-row btns">
                <button @click="onClickClose" class="btn btn-primary cancel m-r-8">취소</button>
                <button @click="onClickConfirm" class="btn btn-primary">확인</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ModalChooseReason',
    data: () => ({
        reasons: ['외모/키', '학력', '직업/직장', '나이', '돌싱', '기타'],
        selectedReason: null,
    }),
    methods: {
        onClickClose() {
            this.$emit('close', '취소')
        },
        async onClickConfirm() {
            if (!this.selectedReason) {
                this.$modal.basic({
                    body: '불합격 이유를 선택해주세요.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
            } else {
                const idx = await this.$modal.basic({
                    body: '해당 회원을 불합격 및 탈퇴처리 하시겠어요?',
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default'
                        },
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                if (idx === 1) {
                    this.$emit('close', this.selectedReason)
                }
            }
        },
        selectReason(reason) {
            this.selectedReason = reason
        },
    },
}
</script>
<style scoped lang="scss">
.reasons {
    height: 30px;
    font-size: 16px;
}
.reason-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .reason-list {
        width: 300px;
        border: 2px solid black;
        text-align: center;
        font-size: 16px;
        border-radius: 8px;
        margin-bottom: 10px;
        height: 50px;
        line-height: 46px;
        cursor: pointer;
        color: black;
        font-weight: 600;
    }
    .reason-list.selected {
        background-color: #ddd;
    }
}
.buttons {
    .btns {
        .cancel {
            color: black;
            background-color: #fdcb6e;
            &:hover {
                background-color: #ffeaa7;
            }
        }
    }
}
</style>
